
import { Options, Vue, prop } from "vue-class-component";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean
  });
  title = prop<string>({
    default: "",
    type: String
  });
  persistent = prop<boolean>({
    default: false,
    type: Boolean
  });
  onReset = prop<Function>({
    default: null,
    type: Function
  });
  showOverflow = prop<boolean>({
    default: false,
    type: Boolean
  });
  header = prop<boolean>({
    default: true,
    type: Boolean
  });
  footer = prop<boolean>({
    default: false,
    type: Boolean
  });
  customBody = prop<string>({
    default: "px-8 py-3",
    type: String
  });
  customHeight = prop<string>({
    default: "",
    type:String
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class Dialog extends Vue.with(Props) {
  closeDialog() {
    if (this.persistent) {
      return;
    }
    this.$emit("update:modelValue", false);
    this.onResetDialog();
  }
  forceCloseDialog() {
    this.$emit("update:modelValue", false);
    this.onResetDialog();
  }
  onResetDialog() {
    if (!this.onReset) return;
    this.onReset();
  }
}
